import React from 'react';

class AboutPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        people: [
            {
              name: 'Anita and Kenneth',
              role: 'Senior Front-end Developer',
              imageUrl:
                'https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
              bio: 'Married with 3 kids, Anita and Kenneth started Lifestyle Fever back in 2015 as a passion project to meet like-minded sex enthusiasts in Taiwan. Anita works as a marketing professional and manages her own food startup with her mother. Kenneth is a veteran of the finance industry with his own arbitrage fund in Hong Kong. Both .',
              twitterUrl: '#',
              linkedinUrl: '#',
            },
            {
                name: 'Anita and Kenneth',
                role: 'Senior Front-end Developer',
                imageUrl:
                  'https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
                bio: 'Married with 3 kids, Anita and Kenneth started Lifestyle Fever back in 2015 as a passion project to meet like-minded sex enthusiasts in Taiwan. Anita works as a marketing professional and manages her own food startup with her mother. Kenneth is a veteran of the finance industry with his own arbitrage fund in Hong Kong. Both .',
                twitterUrl: '#',
                linkedinUrl: '#',
              },
              {
                name: 'Anita and Kenneth',
                role: 'Senior Front-end Developer',
                imageUrl:
                  'https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
                bio: 'Married with 3 kids, Anita and Kenneth started Lifestyle Fever back in 2015 as a passion project to meet like-minded sex enthusiasts in Taiwan. Anita works as a marketing professional and manages her own food startup with her mother. Kenneth is a veteran of the finance industry with his own arbitrage fund in Hong Kong. Both .',
                twitterUrl: '#',
                linkedinUrl: '#',
              },
              {
                name: 'Anita and Kenneth',
                role: 'Senior Front-end Developer',
                imageUrl:
                  'https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
                bio: 'Married with 3 kids, Anita and Kenneth started Lifestyle Fever back in 2015 as a passion project to meet like-minded sex enthusiasts in Taiwan. Anita works as a marketing professional and manages her own food startup with her mother. Kenneth is a veteran of the finance industry with his own arbitrage fund in Hong Kong. Both .',
                twitterUrl: '#',
                linkedinUrl: '#',
              },
              {
                name: 'Anita and Kenneth',
                role: 'Senior Front-end Developer',
                imageUrl:
                  'https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80',
                bio: 'Married with 3 kids, Anita and Kenneth started Lifestyle Fever back in 2015 as a passion project to meet like-minded sex enthusiasts in Taiwan. Anita works as a marketing professional and manages her own food startup with her mother. Kenneth is a veteran of the finance industry with his own arbitrage fund in Hong Kong. Both .',
                twitterUrl: '#',
                linkedinUrl: '#',
              },
            // More people...
          ]
      };
    }

    render() {
        return (
        <>
        <div className="w-full sm:px-6 lg:px-8 h-60 bg-slate-400">

        </div>

        <div className="container mt-2">
            <h1 className='text-2xl mb-16'>About us</h1>

            <section>
                <div className='grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8 items-center'>
                    <div className='grid grid-cols-1 gap-4 lg:col-span-2'>
                        <p>Club Mella is more than just a dating app, it’s a sex-positive community with a vision to help people discover their bliss. From couples curious to learn new ways to introduce passion back into their relationships, to singles seeking out fetish and kink partners, Club Mella encourages the pursuit of desire in an environment that is safe, healthy, and free of shame.</p>
                    </div>

                    <div className='grid grid-cols-1 gap-4'>
                        <div className='p-4'>
                            <div class="m-auto h-80 bg-slate-400">

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <section>
                <h2>Our Values</h2>

                <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                    <div className="border-t border-gray-200 pt-4">
                        <dt className="font-medium text-gray-900">Connection</dt>
                        <dd className="mt-2 text-sm text-gray-500">We aim to foster connections built on respect, open communication, and trust. </dd>
                </div>
                <div className="border-t border-gray-200 pt-4">
                        <dt className="font-medium text-gray-900">Acceptance</dt>
                        <dd className="mt-2 text-sm text-gray-500">An individual's right to pursue their preferences in an environment free from judgment must be respected. </dd>
                </div>
                <div className="border-t border-gray-200 pt-4">
                        <dt className="font-medium text-gray-900">Discovery</dt>
                        <dd className="mt-2 text-sm text-gray-500">We promote the healthy exploration of ideas, knowledge and relationships to help bring exciting experiences to life. </dd>
                </div>
                <div className="border-t border-gray-200 pt-4">
                        <dt className="font-medium text-gray-900">Discretion</dt>
                        <dd className="mt-2 text-sm text-gray-500">Our privacy-first approach ensures couples are protected so they can participate on their own terms and at their own pace. </dd>
                </div>
                <div className="border-t border-gray-200 pt-4">
                        <dt className="font-medium text-gray-900">Playfulness</dt>
                        <dd className="mt-2 text-sm text-gray-500">We infuse elements of fun and liveliness into what we do to create a relaxed environment so people can enjoy themselves on their journey. </dd>
                </div>
                </dl>
            </section>

            <section>
                <div className="w-full sm:px-6 lg:px-8 h-80 bg-slate-400"></div>
            </section>

            <section>
                <h2>Our Team</h2>
                <div className="lg:col-span-2">
                    <ul
                    role="list"
                    className="space-y-12 sm:grid sm:grid-cols-3 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-32"
                    >
                    {this.state.people.map((person) => (
                        <li key={person.name}>
                        <div className="space-y-4">
                            <div className="aspect-w-3 aspect-h-2">
                            <img className="object-cover shadow-lg rounded-lg" src={person.imageUrl} alt="" />
                            </div>
                            <div className="text-lg leading-6 font-medium space-y-1">
                            <h3>{person.name}</h3>
                            <p className="text-indigo-600">{person.role}</p>
                            </div>
                            <div className="text-lg">
                            <p className="text-gray-500">{person.bio}</p>
                            </div>

                            <ul role="list" className="flex space-x-5">
                            <li>
                                <a href={person.twitterUrl} className="text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Twitter</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                                </svg>
                                </a>
                            </li>
                            <li>
                                <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                                <span className="sr-only">LinkedIn</span>
                                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                    <path
                                    fillRule="evenodd"
                                    d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                    clipRule="evenodd"
                                    />
                                </svg>
                                </a>
                            </li>
                            </ul>
                        </div>
                        </li>
                    ))}
                    </ul>
                </div>
            </section>

        </div>

        </>
        );
    }
}


export default AboutPage